@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.table-skeleton {
  width: 100%;
  background-color: $white-color;

  &__row {
    margin: 0 16px;
    border-bottom: 1px solid $bright-color;
    display: grid;
    grid-template-rows: minmax(68px, 68px);
    grid-template-columns:
      minmax(94px, 94px)
      minmax(278px, 1fr)
      minmax(158px, 1fr)
      minmax(252px, 1fr)
      minmax(219px, 1fr)
      minmax(235px, 1fr);

    &__coll {
      padding: 7px;
      margin: 8px 0;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-right: 1px solid $bright-color;
      }

      span {
        width: 100%;
        height: 50px;
        border-radius: 4px;
      }
    }
  }
}
