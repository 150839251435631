@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.select {
  @include flex(column, false, false);
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &__body {
    width: 100%;
    height: 40px;
    @include flex(row, space-between, false);
    padding: 12px 16px;
    white-space: nowrap;
    box-sizing: border-box;
    @include transition(all 0.2s ease-in-out);
    position: relative;
    cursor: pointer;

    &__container {
      width: 100%;
      @include flex(row, false, false);
    }

    &__value {
      width: 95%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__icon {
      .chevron {
        &_up {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__disabled {
    .select__body {
      cursor: no-drop;
    }
  }

  &__options {
    border-radius: 10px;
    border: 1px solid $light-text-color;
    width: 100%;
    max-height: 212px;
    @include flex(column, flex-start, flex-start);
    position: absolute;
    z-index: 100;
    overflow: auto;
    list-style-type: none;

    &__searching {
      padding-top: 0;
    }

    &__creation {
      width: 100%;
      position: relative;

      &__btn {
        width: 100%;
        height: 35px;
        border: none;
        outline: none;
        background-color: $white-color;
        color: $orange;
        padding: 8px 12px;
        cursor: pointer;
        @include font(16px, 400, 19px, false);
        display: inline-flex;
        align-items: center;

        &:hover {
          background-color: $blue-02;
        }
      }

      .input-container {
        &__wrapper {
          position: relative;

          &::after {
            content: '\002B';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 4px;
            left: 13px;
            font-size: 16px;
            color: $grey-03;
          }
        }

        &__text {
          height: 35px;
          background-color: $blue-02 !important;
          border-radius: 0;
          padding-left: 30px;

          @include placeholder {
            color: $grey-03 !important;
            @include font(16px, 400, 19px, false);
          }
        }
      }

      .spinner {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $blue-03;
        z-index: 10;
      }
    }

    &__empty {
      color: $base-text-color;
      @include flex(false, center, center);
    }

    &_sublayer {
      width: 100%;
      @include flex(column, flex-start, flex-start);
    }

    &__item {
      width: 100%;
      @include flex(false, flex-start, flex-start);
      @include transition(color 0.2s ease-in-out);

      & .hovered-star-icon {
        path {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &__label {
        width: 100%;
        min-height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        color: $base-text-color;
        cursor: pointer;

        &:hover {
          background-color: $dark-color;

          .hovered-star-icon {
            path {
              opacity: 1;
            }
          }
        }

        &__selected {
          color: $dark-product-color;
        }
      }

      &__sub-item {
        @include flex(column, flex-start, flex-start);
      }
    }

    &__search {
      width: 100%;
      background: $ultrablack-color;
      position: sticky;
      top: 0;
      z-index: 10;

      .input-container__text {
        min-height: 40px;
      }
    }
  }
}
