@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.card-skeleton {
  width: 100%;
  height: 396px;
  padding: 12px 16px 20px 16px;
  margin-top: 20px;
  background-color: $white-color;
  border-radius: 5px;

  &__head {
    height: 32px;
    @include flex(false, space-between, center);

    &_source {
      width: 100%;
      border-radius: 4px;
      max-width: 204px;
    }

    &_origin {
      width: 100%;
      max-width: 220px;
      border-radius: 4px;
    }
  }

  &__content {
    @include flex(false, space-between, center);
    margin-top: 12px;

    &_media {
      max-height: 266px;
    }

    &__slide {
      width: 100%;
      max-width: 204px;
      height: 124px !important;
    }

    &__preview {
      @include flex(false, flex-start, center);
      margin-top: 12px;
      gap: 22px;
    }

    &__file {
      width: 64px;
      height: 64px !important;
    }

    &_accordion {
      margin-left: 32px;
      width: 100%;
      height: 266px;
    }

    &_text {
      width: 100%;
      height: 10px !important;
      border-radius: 4px;
    }
  }

  &_actions {
    width: 220px;
    height: 32px !important;
    display: block !important;
    margin-top: 24px;
    margin-left: auto;
    border-radius: 4px;
  }
}
