@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'styles/typography.scss';

.geospoof-body {
  height: 100%;
  width: 100%;
  @include flex(column, space-between, center);

  &__header {
    color: $title-color;
    @include font(18px, 500, 32px, false);
    padding: 0 16px;
    margin-top: 18px;
  }

  &__progress {
    width: 100%;
    margin-top: 10px;
    padding: 0 16px;
    @include flex(row, space-between, center);
    gap: 5px;
  }

  &__content {
    @include flex(column, false, flex-start);
    width: 100%;
    height: 100%;

    &__tabs {
      width: 100%;
      padding-bottom: 16px;
      height: 40px;

      .segmented__option {
        padding: 0 10px;
        height: 40px;
        @extend .tpg-b2;
      }
    }

    &__items {
      width: 100%;
      height: auto;
      margin-top: 10px;
      overflow: auto;
    }

    &__controls {
      width: 100%;
      padding: 16px;

      &__search {
        padding: 10px 19px;
        height: 40px;
        border-radius: 0 5px 5px 0;
      }
    }

    .coordinates {
      @include flex(column, false, flex-start);
      gap: 2px;
      margin: 8px 16px;

      p {
        color: $grey-03;
        @include font(12px, 500, 16px, false);
      }
    }
  }

  &__footer {
    width: 100%;
    height: 70px;
    border-top: 1px solid $pink-color;
    @include flex(false, center, false);
  }

  &__error-container {
    padding: 0 16px;
    margin-top: 10px;
    color: $red-color-3;
  }
}
