@import 'styles/mixins.scss';
@import 'styles/variables.scss';

@mixin selected-range-background($left) {
  position: absolute;
  z-index: 1;
  width: 28px;
  height: 100%;
  top: 50%;
  left: $left;
  transform: translateY(-50%);
  background-color: $dark-color;
  content: '';
}

.datepicker-container {
  width: 100%;

  :global {
    .react-datepicker {
      border: 1px solid $bright-color;
      border-radius: 10px;
      background-color: transparent;

      &__close-icon {
        height: 30px;

        &::after {
          font-size: 20px;
          background-color: transparent;
        }
      }
    }

    .react-datepicker {
      z-index: 0;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month-container {
      padding: 14px 14px 12px;
      background-color: $ultrablack-color;
      border-radius: 10px;
      overflow: hidden;
    }

    .react-datepicker__header {
      background-color: $ultrablack-color !important;
      border: none !important;
      border-radius: inherit;
      padding: 0;
    }

    .react-datepicker__current-month {
      @include font(11px, 600, 14px, false);
      color: $title-color;
      text-transform: uppercase;
    }

    .datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      color: $white-color;
    }

    .react-datepicker-popper {
      left: -13px !important;
    }

    .react-datepicker__year-text--in-selecting-range {
      color: $base-text-color;
    }

    .react-datepicker__day-names {
      display: flex;
      gap: 28px;
      margin: 12px 0 0;
    }

    .react-datepicker__day-name {
      @include font(11px, 600, 18px, false);
      width: 20px;
      height: 20px;
      margin: 0;
      color: $base-text-color;
      text-transform: uppercase;
    }

    .react-datepicker__month {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin: 6px 0 0;
    }

    .react-datepicker__week {
      display: flex;
      gap: 28px;
    }

    .react-datepicker__day {
      @include font(11px, 600, 18px, false);
      position: relative;
      width: 20px;
      height: 20px;
      margin: 0;
      color: $title-color;
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      @include transition(background-color 0.2s ease-in-out !important);
      text-align: center;
      color: $title-color !important;
      background-color: $dark-color;

      &:hover {
        background-color: $dark-product-color;
      }
    }

    .react-datepicker__day--in-range {
      &::before {
        @include selected-range-background(-100%);
      }
      &::after {
        @include selected-range-background(50%);
      }
    }

    .react-datepicker__day--in-range.react-datepicker__day--range-start {
      background-color: $dark-product-color;
      &::before {
        display: none;
      }
    }

    .react-datepicker__day--in-range.react-datepicker__day--range-end {
      background-color: $dark-product-color;
      &::after {
        display: none;
      }
    }

    .react-datepicker__navigation {
      top: 7px;
    }

    .react-datepicker__navigation--next {
      right: 9px;
    }

    .react-datepicker__navigation--previous {
      left: 9px;
    }

    .react-datepicker__navigation-icon::before {
      top: unset;
    }

    .react-datepicker__navigation-icon--next::before {
      background: url('../../../images/newIcons/forward.svg') no-repeat !important;
      width: 20px;
      height: 20px;
      transform: none !important;
      border: none !important;
    }

    .react-datepicker__navigation-icon--previous::before {
      background: url('../../../images/newIcons/backward.svg') no-repeat !important;
      border: none !important;
      width: 20px;
      height: 20px;
      transform: none !important;
    }

    .react-datepicker__month--selecting-range
      .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
      ),
    .react-datepicker__month--selecting-range
      .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
      ),
    .react-datepicker__month--selecting-range
      .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
      ),
    .react-datepicker__month--selecting-range
      .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
      ) {
      color: $black-color !important;
    }
  }
}

.custom-datepicker {
  width: 100%;
}

.custom-datepicker-input {
  width: 100%;
  min-height: 20px;
  color: $base-text-color;
  background-color: $light-color;
  border: none;
  border-radius: 5px;
  padding: 10px;

  &:focus {
    border: none;
    outline: none;
  }

  @include placeholder {
    @include font(14px, 400, 14px, false);
    color: $base-text-color;
    text-align: center;
  }

  &_black {
    @include placeholder {
      text-align: left;
      color: $white-color;
      padding: 0 10px;
    }

    min-height: unset;
    background-color: $light-color;
    color: $title-color;
    padding: 5px 10px;
  }

  &_clearable {
    padding-right: 25px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.custom-datepicker-day {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: inherit;
  border-radius: inherit;

  &:hover {
    background-color: $dark-color;
    outline: 1px solid $dark-product-color;
  }
}
