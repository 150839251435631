@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.converter {
  &__modal {
    z-index: 9999;
    padding: 48px;
    border-radius: 10px;
    border: 1px solid $light-text-color;
    background-color: $dark-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 450px;

    &__default-options {
      width: 100%;
      gap: 12px;
    }
  }

  &__customer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.copy-icon {
  &:hover {
    path {
      fill: $dark-product-color;
    }
  }
}
