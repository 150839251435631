@import 'styles/variables.scss';

.legend {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: fit-content;
  margin-bottom: 68px;
  background-color: $dark-color;
  color: $title-color;
  max-height: 500px;
  min-width: 615px;
  overflow-y: auto;
  border: 1px solid $light-text-color;

  &__title {
    border-top: 1px solid $light-text-color;
  }

  &__element {
    width: 100%;
    min-width: 150px;
    &__with-border {
      border-right: 1px solid $light-text-color;
    }
  }

  &__chevron {
    cursor: pointer;
    line-height: 0;
    transform: rotate(0);
    path {
      fill: $title-color;
    }
    &_up {
      transform: rotate(180deg);
    }
  }
}

.figure {
  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    margin-right: 8px;
  }

  &__line {
    width: 16px;
    height: 3px;
    position: relative;
    margin-right: 8px;
  }
}

.info {
  &__block {
    color: $title-color;
    gap: 4px;
  }
}
