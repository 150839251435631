@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.avatar {
  position: relative;
  border-radius: 50%;
  @include flex(false, center, center);

  &__loader {
    width: inherit;
    display: block !important;
    position: absolute !important;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &_image {
    width: inherit;
    height: inherit;
    border-radius: 50%;
  }

  &__search-icon {
    width: 30px;
    height: 30px;

    path {
      fill: $orange;
    }
  }

  &__expand {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    @include transition(opacity 0.2s);
    opacity: 0;
    cursor: pointer;
    @include flex(false, center, center);
    @include gradient-2();

    svg path {
      fill: white;
    }
  }

  &:hover .avatar__expand {
    opacity: 1;
  }

  &__theme {
    &-grey {
      background-color: $light-color;

      .avatar__loader {
        @include gradient-2();
      }

      path {
        fill: #fff;
      }
    }

    &-blue {
      background-color: $light-blue-02;

      .avatar {
        &__loader {
          background-color: $light-blue-02 !important;
        }

        &__search-icon path {
          fill: $turquoise;
        }
      }
    }

    &-orange {
      background-color: $orange-02;

      .avatar__loader {
        background-color: $orange-02 !important;
      }
    }
  }
}
