@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.tags-container {
  @include transition(height 0.3s, overflow 0.3s);
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: inherit;
  margin: 12px 0px;
  width: inherit;
  height: max-content;

  &__list {
    @include flex(row, false, flex-start);
    flex-wrap: wrap;
    background-color: inherit;
    gap: 4px;
    align-self: stretch;
    height: inherit;
  }
}
