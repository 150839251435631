@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.error-boundary {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_image {
    max-width: 200px;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 1.41421;

    .cog-blue {
      transform-origin: 306.785px 190.5px;
      transform: translateY(-15px);
      animation: spin 4s cubic-bezier(0.47, 0, 0.75, 0.72);
      transition-duration: 20s;
    }

    .cog-dark {
      transform-origin: 207.352px 357.835px;
      transform: translateX(-15px);
      animation: spin-reverse 4s cubic-bezier(0.47, 0, 0.75, 0.72);
      transition-duration: 20s;
    }

    .cog-ligth {
      transform-origin: 393.785px 409.5px;
      transform: translateY(15px);
      animation: spin-dropdown 4s cubic-bezier(0.47, 0, 0.75, 0.72);
      transition-duration: 20s;
    }
  }

  &_label {
    @include font(48px, 600, 55px, 0.05em);
    padding: 16px 0;
  }

  &_description {
    font-size: 28px;
    color: $base-color;
  }

  &_link {
    padding: 16px 0;
    font-size: 20px;
    color: $blue-color;
    cursor: pointer;
  }

  @keyframes spin {
    0% {
      transform: translateY(0px);
      transform: rotate(30deg);
    }

    25% {
      transform: rotate(-30deg);
    }

    50% {
      transform: rotate(60deg);
    }

    75% {
      transform: rotate(-60deg);
    }

    95% {
      transform: translateY(0px);
    }

    100% {
      transform: rotate(30deg);
      transform: translateY(-15px);
    }
  }

  @keyframes spin-dropdown {
    0% {
      transform: translateY(0px);
      transform: rotate(30deg);
    }

    25% {
      transform: rotate(-30deg);
    }

    50% {
      transform: rotate(60deg);
    }

    75% {
      transform: rotate(-60deg);
    }

    95% {
      transform: translateY(0px);
    }

    100% {
      transform: rotate(30deg);
      transform: translateY(15px);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: translateX(0px);
      transform: rotate(-30deg);
    }

    25% {
      transform: rotate(30deg);
    }

    50% {
      transform: rotate(-60deg);
    }

    75% {
      transform: rotate(60deg);
    }

    95% {
      transform: translateX(0px);
    }

    100% {
      transform: rotate(-30deg);
      transform: translateX(-15px);
    }
  }
}
