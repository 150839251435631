@import 'styles/_variables.scss';

.burger-menu {
  width: 300px;
  height: 100%;
  background-color: $dark-color;
  border-radius: 5px;
  border: 1px solid $light-color;
  padding: 12px 16px;

  &__options {
    cursor: pointer;
    padding-top: 8px;
    color: $base-text-color;
  }

  &__options:hover {
    cursor: pointer;
    color: $title-color;
  }

  &__icon {
    width: 40px;
    height: 40px;
    padding: 5px;

    &.active {
      path {
        fill: $bright-product-color;
      }
    }
    &:hover {
      path {
        fill: $dark-product-color;
      }
    }
  }
}
