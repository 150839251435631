@import 'styles/mixins.scss';

.round-loader {
  width: 100%;
  @include flex(column, center, center);

  &__spinner {
    animation: 2s linear 0s normal none infinite running geospoof-spin;
    -webkit-animation: 2s linear 0s normal none infinite running geospoof-spin;
  }

  @keyframes geospoof-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
