@import 'styles/variables.scss';

.gc-popup {
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
  background-color: $dark-color;

  &__title {
    font-weight: bold;
  }

  &__coordinates {
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }
}

.mapboxgl-overrides {
  .mapboxgl-popup-content {
    background-color: $dark-color;
  }

  .mapboxgl-popup-tip {
    border-bottom-color: $dark-color;
  }

  .mapboxgl-popup-close-button {
    color: $title-color;
    font-size: 20px;
  }
}
