@import 'styles/_mixins.scss';
@import 'styles/_variables.scss';

.header {
  @include flex(row, space-between, false);
  width: 100%;
  height: 40px;
  padding: 0 24px;
  background-color: $light-color;
  color: $title-color;

  &__m {
    color: $bright-product-color;
  }

  &__links {
    height: 40px;
    @include flex(row, false, false);
    gap: 0;
    cursor: pointer;
  }

  &__logo .header-link__title {
    color: $title-color;
    @include font(24px, 700, 32px, false);
  }
  &__logo .header-link__title-m {
    color: $bright-product-color;
    @include font(24px, 700, 32px, false);
  }
}
