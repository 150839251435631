@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.segmented {
  @include flex(false, center, false);
  background-color: $light-color;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  backdrop-filter: blur(8px);

  &__option {
    cursor: pointer;
    @include flex(false, center, false);
    padding: 8px 0;
    flex: 1;
    color: $base-text-color;
    @include font(16px, 400, 24px, false);

    &.active {
      background-color: $dark-color;
      color: $title-color;
    }

    &.first-option {
      border-top-left-radius: inherit;
    }

    &.last-option {
      border-top-right-radius: inherit;
    }
  }
}
