@import 'styles/variables.scss';
@import 'styles/_mixins.scss';

.checkbox-container {
  line-height: 0;

  &__input {
    &__circle {
      min-width: 16px;
      min-height: 16px;
      position: relative;
      border: 1px solid $light-color;
      border-radius: 50%;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      appearance: none;

      &:hover {
        background-color: $bright-color;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $dark-product-color;
        opacity: 0;
        @include transition(opacity 0.3s);
      }

      &:checked {
        border-color: $light-color;

        &:before {
          opacity: 1;
        }
      }
    }

    &__square {
      min-width: 16px;
      min-height: 16px;
      position: relative;
      border: 1px solid $light-color;
      border-radius: 3px;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      appearance: none;
      @include transition(background-color 0.5s);

      &:hover {
        background-color: $bright-color;
      }

      &:before {
        content: '';
        width: 11px;
        height: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background-image: url('../../../images/newIcons/check.svg');
        background-repeat: no-repeat;
        background-size: 100%;

        path {
          fill: $title-color;
        }

        opacity: 0;
        @include transition(opacity 0.3s);
      }

      &:checked {
        background: $main-product-color;
        border-color: $main-product-color;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    min-width: 20px;
    color: $base-text-color;
    display: inline-flex;
    cursor: pointer;
  }
}
