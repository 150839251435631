@import 'mixins.scss';
@import 'variables.scss';

.astra-modal {
  width: 100%;
  @include flex(column, center, center);
  padding: 28px 16px 40px;
  background-color: $dark-color;
  border-radius: 5px;

  &__title {
    @include font(32px, 500, 32px, false);
    color: $title-color;
  }

  &__content {
    width: 100%;
    @include flex(column);
    margin-top: 34px;
    gap: 16px;
  }

  &__options {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 12px;

    .custom-datepicker__input {
      background-color: $light-color;
      color: $title-color;
    }

    &-warning {
      color: $red-color;
      @include font(12px, 500, 14px, false);
    }

    &-name {
      color: $title-color;
      @include font(16px, 700, 14px, false);
    }

    &-filename {
      @include font(14px, 500, 14px, false);
      cursor: pointer;
    }

    .input-container {
      &__text {
        padding: 5px 12px;
        background-color: $light-color;
        color: $title-color;

        @include placeholder {
          color: $base-text-color;
        }
      }

      &__input-search {
        padding-left: 30px;
      }
    }
  }

  &__actions {
    width: 100%;
    @include flex(false, space-between, center);
    gap: 16px;

    .astra-button {
      width: 100%;
      padding: 10px 12px;
      margin-top: 16px;
      border-radius: 5px;
      backdrop-filter: blur(16px);

      @include font(16px, 600, 20px, false);
    }
  }
}

.astra-single-page-modal {
  padding: 48px;
  border-radius: 10px;
  border: 1px solid $light-text-color;
  background-color: $dark-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: auto;
  max-height: calc(100% - 10%);
}
