@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.markdown-editor {
  width: 100%;
  background-color: $ultrablack-color;
  color: $title-color;
}

.mdxeditor {
  background-color: $ultrablack-color;
  color: $title-color;

  &-toolbar {
    background-color: $dark-color;
    color: $title-color;
    border-radius: 0;
  }

  &-select-content {
    background-color: $dark-color;
    color: $title-color;
  }

  &-popup-container {
    background-color: $red-color;
    color: $title-color;
  }
}

h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 3.84px;
}

h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 2.64px;
}

h4 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.44px;
}

link {
  color: var(----oc-blue-7, #1c7ed6);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
}

//ul {
//  font-size: 14px;
//  margin-left: 10px;
//  list-style-type: circle;
//}
//
//ol {
//  font-size: 14px;
//  margin-left: 10px;
//  list-style-type: circle;
//}
