@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.modal {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flex(false, center, false);

  &__blurred {
    backdrop-filter: blur(5px);
    background-color: rgba($ultrablack-color, 0.7);
    margin-top: 88px;
  }

  &__body {
    width: 90%;
    position: relative;
    @include flex(false, center, false);
    justify-content: center;

    &__close-icon {
      cursor: pointer;
      @include flex(false, center, false);
      width: 24px;
      height: 24px;
      position: absolute;
      top: -24px;
      right: -24px;

      svg path {
        fill: $white-color;
      }
    }
  }
}

.confirm-modal {
  padding: 48px;
  border-radius: 10px;
  border: 1px solid $light-text-color;
  background-color: $dark-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 460px;
  margin-bottom: 100px;
}
