@import 'styles/variables.scss';
@import 'styles/_mixins.scss';

.switch {
  &-s {
    width: 28px;
    height: 16px;
    position: relative;
    display: inline-block;

    &__handle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 100px;
      border: 1px solid white;
      @include transition(background-color 0.3s);
      cursor: pointer;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        background-color: $white-color;
        border-radius: 50%;
        @include transition(transform 0.3s);
      }
    }

    input {
      display: none;

      &:checked + .switch-s__handle {
        border: 1px solid $dark-product-color;
        top: 0;
        background-color: $dark-product-color;

        &:before {
          transform: translateX(12px);
          background-color: $white-color;
        }
      }
    }
  }

  &-m {
    width: 40px;
    height: 24px;
    position: relative;
    display: inline-block;

    &__handle {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 100px;
      border: 1px solid white;
      @include transition(background-color 0.3s);
      cursor: pointer;

      &::before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        background-color: $white-color;
        border-radius: 50%;
        @include transition(transform 0.3s);
      }
    }

    input {
      display: none;

      &:checked + .switch-m__handle {
        border: 1px solid $dark-product-color;
        top: 0;
        background-color: $dark-product-color;

        &:before {
          transform: translateX(16px);
          background-color: $white-color;
        }
      }
    }
  }
}
