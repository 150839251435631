@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.accordion {
  width: 100%;
  position: relative;
  background-color: $light-color;
  padding: 8px 16px 4px;
  border-radius: 5px;

  &__content {
    color: $title-color;
    overflow: hidden;
    text-overflow: ellipsis;
    @include transition(max-height 0.3s);

    em {
      font-weight: 500;
    }

    strong {
      font-weight: 800;
    }
  }

  &__hidden-box {
    width: 100%;
    height: 32px;
    position: absolute;
    left: 0;
    bottom: 0;
    @include flex(false, center, flex-end);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $light-color 100%
    );

    &_visible {
      background: none;
    }
  }

  &__icon-show-more {
    position: absolute;
    bottom: -20px;
    cursor: pointer;

    path {
      fill: $base-text-color;
    }

    &_active {
      transform: rotate(180deg);
    }
  }
}
