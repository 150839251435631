@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.table {
  thead {
    width: 100%;
    @include flex();
    background-color: $dark-color;
    position: sticky;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid $light-text-color;

    tr {
      height: 50px;
      margin: 0 16px;
      vertical-align: middle;

      th {
        height: 35px;
        padding: 0 16px;
        margin: 8px 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          border-right: 1px solid $bright-color;
        }
      }
    }
  }

  &__sort-actions {
    height: 24px;
    @include flex(column);
    position: relative;

    &__icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
      position: absolute;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }

      path {
        fill: $grey-04;
      }

      &-active {
        path {
          fill: $base-color;
        }
      }
    }
  }

  tbody {
    width: 100%;
    display: inline-block;
    background-color: $dark-color;
  }
}

.MuiSvgIcon-root {
  path {
    fill: $light-text-color;
  }
}
.MuiList-root {
  background-color: $dark-color;
  color: $title-color;
}
.MuiToolbar-root {
  color: $title-color;
}
.MuiBox-root {
  background-color: $dark-color;
  border-radius: 10px;
}
.MuiTableCell-root {
  color: $base-text-color !important;
  border-bottom: 1px solid $main-product-color !important;
  border-right: 1px solid $main-product-color;
  &:last-child {
    border-right: none !important;
  }
  &:hover {
    background-color: $ultrablack-color;
  }
}
.MuiTableCell-root.content-column {
  min-width: 48px;
  border-bottom: 1px solid $light-text-color !important;
  border-right: 1px solid $light-text-color !important;
}
.MuiTableCell-root.first-column {
  min-width: 48px;
  border-bottom: 1px solid $light-text-color !important;
  border-right: 1px solid $light-text-color !important;
}
.MuiTableHead-root {
  .MuiTableCell-root {
    border-bottom: 1px solid $light-text-color !important;
    border-right: 1px solid $light-text-color;

    &:last-child {
      border-right: none;
    }
  }
}
.MuiTableCell-head {
  background-color: $light-color !important;
}
.MuiTablePagination-root {
  color: $base-text-color;
}

.MuiTable-root {
  border: 0px solid transparent;
}
