@import 'styles/_mixins.scss';
@import 'styles/variables.scss';

.preview {
  cursor: pointer;

  &__play-icon {
    width: 32px;
    height: 32px;

    path {
      fill: $white-color;
    }
  }

  &__close-icon-s {
    position: absolute;
    top: 0;
    right: 0;
    @include flex(row, center, false);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $main-product-color;
    path {
      fill: white;
    }
  }

  &__close-icon-m {
    position: absolute;
    top: -5px;
    right: -5px;
    @include flex(row, center, false);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $main-product-color;
    path {
      fill: white;
    }
  }

  &__list {
    width: 100%;
    @include flex(false, flex-start, center);
    gap: 12px;

    &-item-s {
      width: 48px;
      height: 48px;
      min-width: 44px;
      @include flex(false, center, center);
      border-radius: 5px;
      position: relative;

      img,
      &__more {
        width: 48px;
        height: 48px;
        min-width: 44px;
        object-fit: cover;
        border-radius: 5px;
      }

      &_play-icon {
        width: 32px;
        height: 32px;

        path {
          fill: $white-color;
        }
      }

      &__more {
        @include flex(false, center, center);
        &-hidden {
          display: none;
        }
      }
    }

    &-item-m {
      width: 76px;
      height: 76px;
      min-width: 76px;
      @include flex(false, center, center);
      border-radius: 5px;
      position: relative;

      img,
      &__more {
        width: 76px;
        height: 76px;
        min-width: 76px;
        object-fit: cover;
        border-radius: 5px;
      }

      &_play-icon {
        width: 32px;
        height: 32px;

        path {
          fill: $white-color;
        }
      }

      &__more {
        @include flex(false, center, center);
        &-hidden {
          display: none;
        }
      }
    }

    &_expanded {
      width: 100%;
      overflow: auto;
    }
  }

  &__slide {
    width: 100%;
    height: auto;
    max-width: 204px;
    max-height: 124px;
    min-height: 124px;
    object-fit: cover;

    svg {
      width: 64px;
      height: 64px;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 204px;
      max-height: 124px;
      object-fit: cover;
    }
  }
}
