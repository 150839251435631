@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.context-popup {
  @include flex(column, false, left);

  .astra-button {
    width: auto;

    span {
      color: $base-text-color;
    }

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;

    &:hover {
      span {
        color: $main-product-color;
      }
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
    }

    transition: none !important;
  }

  &-elevation {
    text-align: center;
    margin-bottom: -15px;
    margin-top: -5px;
    margin-left: 15px;
  }
}

.map-popup-button {
  padding: 0 16px;
  height: 32px;
  background-color: $dark-color;
  color: $base-text-color;
  justify-content: left;

  &:hover {
    color: $bright-product-color;
    background-color: $dark-color;
  }
}

.popup-override {
  z-index: 2;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    padding: 6px 0px 6px 0px;
    background-color: $dark-color;
    border-radius: 10px !important;
    width: 300px;
  }
}
