.object-popup {
  :global {
    .mapboxgl-popup-content {
      background: inherit;
      padding: inherit;
      border-radius: inherit;

      & > div {
        border-radius: inherit;
      }
    }
  }

  &_tip_hidden {
    :global {
      .mapboxgl-popup-tip {
        display: none;
      }
    }
  }
}
