@import 'styles/variables.scss';

.tag-container {
  border-radius: 5px;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
  position: relative;
  column-gap: 4px;

  &__close-icon {
    height: 16px;
    width: 16px;
    cursor: pointer;

    path {
      fill: $bright-product-color;
    }

    &:hover {
      path {
        fill: $title-color;
      }
    }
  }
}
