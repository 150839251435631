@import 'styles/mixins.scss';

.radio-group {
  @include flex(column, false, flex-start);
  gap: 12px;

  &__header {
    margin-bottom: 6px;
  }
}
