@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'styles/typography.scss';
@import 'styles/cursors.scss';
@import 'mapbox-gl/dist/mapbox-gl.css';

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  @include flex();

  &__loader {
    width: 100%;
    height: 100%;
    @include flex(false, center, center);
    position: absolute;
    top: 0;
    left: 0;
    background-color: $light-blue-color;
    z-index: 10;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    &__svg-pointer {
      svg {
        @extend .grab-custom-cursor;
      }
    }
  }

  .mapboxgl-ctrl {
    &-group {
      background: transparent;
      box-shadow: none !important;
      border: none;
      gap: 8px;

      button {
        width: 40px;
        min-height: 40px;
        background-color: $light-color;
        border: none;

        &.active {
          background-color: $bright-product-color;
        }

        &:not(:disabled):not(.active):hover {
          background-color: $bright-color;
        }
      }
    }

    &-top-right .mapboxgl-ctrl {
      margin: 8px 16px 0 0;
    }

    button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
      background-image: url('../../images/icons/geolocate.svg');
      background-repeat: no-repeat;
    }

    button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      background-image: url('../../images/icons/plus.svg');
      background-repeat: no-repeat;
    }

    button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      background-image: url('../../images/icons/minus.svg');
      background-repeat: no-repeat;
    }

    &-bottom-left {
      width: 100%;
      margin: 0 0 24px 38px;
      gap: 8px;
      display: flex;
      justify-content: center;
      // keeps right controls order
      flex-direction: row-reverse;

      .mapboxgl-ctrl-group {
        margin: 0;
        gap: 4px;
        @include flex();
      }

      .mapboxgl-ctrl:last-child {
        display: none !important;
      }
    }

    &-bottom-left {
      margin: 0 16px 24px 0;
      gap: 4px;

      .mapboxgl-ctrl {
        &-group {
          margin: 0;
          @include flex();
        }
      }

      .mapboxgl-ctrl-measurement {
        background-image: url('../../images/icons/white-ruler.svg');
        background-repeat: no-repeat;
        background-position: 50%;

        &.active {
          background-color: $bright-product-color;
        }
      }

      .mapboxgl-ctrl-legend {
        background-image: url('../../images/icons/info-white.svg');
        background-repeat: no-repeat;
        background-position: 50%;

        &.active {
          background-color: $bright-product-color;
        }
      }

      .mapboxgl-ctrl-terrain {
        background-image: url('../../images/icons/rotation.svg');
        background-repeat: no-repeat;
        background-position: 50%;

        &.active {
          background-color: $bright-product-color;
        }
      }

      .mapboxgl-ctrl-attrib {
        display: none;
      }
    }
  }

  .custom-marker {
    max-width: 56px;
    max-height: 56px;
    object-fit: contain;
    border-radius: 50%;
  }

  .mapboxgl-canvas {
    width: 100% !important;
  }
}
