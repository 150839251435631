@import 'styles/mixins.scss';

.filter-dropdown {
  width: 100%;

  &__title {
    @include flex(row, false, false);
    gap: 4px;

    &__chevron {
      cursor: pointer;
      line-height: 0;

      &_up {
        transform: rotate(180deg);
      }
    }
  }

  &__container {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s;

    &__active {
      max-height: 9999px;
      transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
      transition-delay: 0s;
    }
  }

  &__options {
    padding: 8px 0 10px 28px;
    @include flex(column, false, flex-start);
    gap: 12px;

    .input-container__text {
      padding: 8px 12px 8px 30px;
    }
  }

  &__head-filter {
    .filter-dropdown__options {
      padding: 0;
    }
  }
}
