@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.textarea {
  color: $title-color;
  &__children {
    right: 0;
    top: 0;
    margin-top: 16px;
    margin-right: 16px;
    cursor: pointer;
    path {
      fill: $light-text-color;
    }
  }

  textarea {
    resize: vertical;
    color: $base-text-color;
    width: 100%;
    border: none;
    padding: 12px;
    @include font(15px, 400, 16px, 0.16px);

    &::-webkit-input-placeholder {
      @include font(15px, 400, 16px, 0.16px);
      color: $base-text-color !important;
    }

    &:focus-visible,
    &:focus {
      border: none;
      outline: none;
    }
  }
}
