@import 'styles/_mixins.scss';
@import 'styles/_variables.scss';
@import 'styles/common.scss';

.header-link {
  @include flex(row, false, center);
  gap: 4px;

  svg path {
    @include transition(fill 0.2s ease-in-out);
    fill: $base-text-color;
  }

  &__title {
    color: $base-text-color;
    @include transition(color 0.2s ease-in-out);

    &:hover {
      color: $title-color;
    }
  }

  &:hover {
    svg path {
      fill: $title-color;
    }
  }

  &__active {
    .header-link__title {
      @extend .text-primary;
    }
    background-color: $dark-color;
    svg path {
      fill: $title-color;
    }
  }
}
