@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.color-picker {
  width: 90%;

  &__container {
    @include flex(false, flex-start, center);
    flex-wrap: wrap;
    gap: 4px;
  }

  &__title {
    @include font(16px, 700, 14px, false);
    color: $title-color;
  }

  input[type='radio'] {
    display: none;
  }

  label {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &.empty-color {
      background-image: linear-gradient(
        to bottom right,
        transparent 45%,
        #eb5757,
        transparent 54%
      );
      background-repeat: no-repeat;
    }

    &:before,
    &:after {
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
    }

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      background: $base-color;
      @include transition(all 0.2s);
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      background: $light-blue-color;
    }
  }

  input:checked + label {
    &:before {
      transform: translate(-50%, -50%) scale(1);
      @include transition(all 0.4s);
    }

    &:after {
      background: transparent;
      @include transition(all 0.5s);
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
