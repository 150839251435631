@import 'styles/variables.scss';
@import 'styles/typography.scss';

.toast-container {
  top: 124px !important;
  width: 360px !important;
  height: 83px !important;
  padding: 0px !important;
}

.toast {
  background-color: $dark-color !important;
  color: $title-color !important;
  padding: 16px !important;
}

.close-icon {
  height: 16px;
  width: 16px;
  flex-shrink: 0;

  path {
    fill: $title-color;
  }

  &:hover {
    path {
      fill: $bright-product-color;
    }
  }
}

.toast-body {
  padding: 0px;
  margin-right: 10px;
  font-family: 'Proxima Nova', system-ui, sans-serif;
}
