@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.astra-dropdown {
  opacity: 1 !important;
  border-radius: 10px;
  background-color: $ultrablack-color !important;
  border: $bright-color solid 1px;
  padding: 0 !important;

  &__actions {
    @include flex(column, false, flex-start);

    button {
      width: 100%;
      border: none;
      outline: none;
      background-color: $white-color;
      padding: 8px 15px;
      text-align: start;
      cursor: pointer;

      &:hover {
        background-color: $pink-color;
      }
    }

    &__danger {
      color: $red-color;
    }
  }

  .rc-tooltip {
    &-content {
      width: 200px;
      border-radius: 5px;
    }

    &-inner {
      padding: 6px 0;
      background-color: transparent;
      border: none;

      button {
        cursor: pointer;
        color: $base-text-color;

        &:hover {
          background-color: $dark-color;
        }
      }
    }

    &-arrow {
      border-top-color: $orange !important;
    }
  }
}

.bar-droplist {
  opacity: 1 !important;

  .rc-tooltip-inner {
    background-color: $ultrablack-color;
    border: $bright-color solid 1px;
    border-radius: 10px;
    padding: 6px 0;
  }
}

.bar-droplist-option {
  color: $base-text-color;
  padding: 0 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-align: left;
}
