@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.map-types {
  &__fire-date {
    padding-top: 8px;
    p {
      color: $title-color;
      margin-bottom: 5px;
    }
  }
}
