@import 'styles/variables.scss';

.geolocate-icon {
  width: 35px;
  height: 35px;

  path {
    fill: $main-product-color;
  }

  stroke: $base-color;
}
