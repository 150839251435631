@import 'variables.scss';

.bg-primary {
  background-color: $ultrablack-color;
}

.text-primary {
  color: $title-color;
}
.icon-title {
  path {
    fill: $title-color;
  }
}

.text-main-pc {
  color: $dark-product-color;
}

.icon-main-pc {
  path {
    fill: $dark-product-color;
  }
}

.text-bright-pc {
  color: $bright-product-color;
}

.icon-bright-pc {
  path {
    fill: $bright-product-color;
  }
}

.text-light-pc {
  color: $main-product-color;
}

.icon-light-pc {
  path {
    fill: $main-product-color;
  }
}

.text-hint {
  color: $light-text-color;
}

.icon-light-text {
  path {
    fill: $light-text-color;
  }
}
