@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.card {
  width: 100%;
  @include flex(column, flex-start, flex-start);
  padding: 12px 16px 20px;
  background: $dark-color;
  border-radius: 5px;

  &__head {
    width: 100%;
    @include flex(false, space-between, center);
    gap: 12px;
  }

  &__source-container {
    width: 30%;
    @include flex(false, flex-start, flex-start);
  }

  &__match-container {
    width: 40%;
    color: $light-grey;
    @include flex(false, flex-start, flex-start);
    @include font(14px, 700, 32px, false);
  }

  &__source-item {
    width: max-content;

    &_date {
      white-space: nowrap;
    }

    &_date,
    &_name {
      @include font(14px, 500, 32px, false);
      color: $base-text-color;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__source-item_name,
  &__match-items {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__origin {
    &-container {
      @include flex();
    }

    &-parser {
      width: max-content;
      color: $light-grey;
    }

    &-link {
      width: max-content;
      @include font(14px, 400, 32px, false);
      color: $base-text-color !important;
      text-decoration-line: underline !important;
      text-underline-offset: 3px;
    }
  }

  &__content {
    @include flex(false, space-between, flex-start);
    width: 100%;
    margin-top: 12px;
  }

  &__media {
    &-files {
      width: 100%;
      max-width: 204px;
    }

    &-link {
      display: inline-flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;

      svg {
        margin-right: 4px;
      }
    }

    &-files {
      margin-right: 16px;
    }
  }

  .preview__list {
    margin-top: 12px;
  }

  &__actions {
    width: 100%;
    margin-top: 24px;
    @include flex(false, flex-end, center);

    &-icon {
      cursor: pointer;

      path {
        fill: $base-text-color !important;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__tooltip {
    background-color: $orange !important;
    border-radius: 5px;

    .rc-tooltip {
      &-content {
        border-radius: 5px;
      }

      &-inner {
        max-width: 500px;
        background: $orange;
        color: $base-color;
        border-radius: 5px;
        border: none;
      }

      &-arrow {
        border-top-color: $orange !important;
      }
    }
  }
}
