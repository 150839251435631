@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.login {
  width: 100%;
  height: 100%;
  background-color: $ultrablack-color;
  @include flex(column, center, center);

  &_container {
    width: 100%;
    max-width: 472px;
    padding: 28px 16px 40px;
    border-radius: 5px;
    background: $dark-color;
    @include flex(column, center, center);
  }

  &__title {
    color: $title-color;
  }

  &__form {
    width: 100%;
    margin-top: 34px;
    @include flex(column, center, center);

    .input-container {
      min-height: 74px;
      justify-content: flex-start;

      &__text {
        background-color: $bright-color;
        padding: 5px 30px 5px 12px;
      }

      &__label {
        color: $title-color;
        margin-bottom: 12px;
        @include font(16px, 700, 14px, false);
      }

      &__password {
        right: 12px;
      }
    }

    .astra-button {
      width: 100%;
      @include font(16px, 600, 20px, false);
      border-radius: 5px;
      margin-top: 32px;
    }
  }

  &_password {
    width: max-content;
    color: $blue-color;
    margin: 4px 0 0 auto;

    &:hover {
      color: $turquoise;
    }
  }
}
