@tailwind base;
@tailwind components;
@tailwind utilities;
@import './typography';
@import './variables.scss';
@import './_mixins.scss';
@import './reset.scss';
@import './modal.scss';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import 'rc-tooltip/assets/bootstrap_white.css';
@import 'common.scss';

@layer base {
  html {
    font-family: 'Proxima Nova', system-ui, sans-serif;
  }
}
html,
body {
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-family: 'Proxima Nova', system-ui, sans-serif;
}

@property --scrollbar-opacity-var {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(
    $color: $bright-product-color,
    $alpha: var(--scrollbar-opacity-var)
  );
  border-color: transparent;
  border-radius: 4px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track-piece {
  display: none;
}

* {
  --scrollbar-opacity-var: 0;
  transition: --scrollbar-opacity-var 0.15s ease-in-out;
}

*:hover {
  --scrollbar-opacity-var: 1;
}

@layer utilities {
  .cursor-custom-default {
    cursor: url('../images/cursors/cursorDefault.svg'), default;
  }

  .cursor-custom-crosshair {
    cursor: url('../images/cursors/cursorCrosshair.svg') 12 12, crosshair;
  }

  .cursor-custom-grab {
    cursor: url('../images/cursors/cursorGrab.svg'), grab;
  }

  .cursor-custom-grabbing {
    cursor: url('../images/cursors/cursorGrabbing.svg'), grabbing;
  }

  .cursor-custom-copy {
    cursor: url('../images/cursors/cursorCopy.svg'), copy;
  }

  .cursor-custom-move {
    cursor: url('../images/cursors/cursorMove.svg'), move;
  }

  .cursor-custom-pointer {
    cursor: url('../images/cursors/cursorPointer.svg'), pointer;
  }
}

body,
html,
#root {
  color: $title-color;
  background-color: $ultrablack-color;
  height: 100%;
  width: 100%;
  min-height: 100%;
}

#root {
  overflow: hidden;
}

.icon {
  path {
    fill: inherit;
  }
}

mark {
  border-radius: 5px;
  background-color: $orange;
  padding: 0 4px;
}

.skeleton {
  height: inherit;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($light-silver, 0) 0,
      rgba($silver, 0.5) 20%,
      rgba($silver, 0.8) 60%,
      rgba($light-silver, 0)
    );
    background-repeat: no-repeat;
    animation: shine 1.5s ease infinite;
  }

  @keyframes shine {
    to {
      transform: translateX(100%);
    }
  }
}

.scrollbar_hidden {
  @include scrollbar_hidden();
}

.mapboxgl-popup,
.modal__body {
  font-family: 'Proxima Nova';
  font-style: normal;
}

.mapboxgl-canvas {
  @include transition(width 0.3s linear);
}

.highlighted {
  border-radius: 5px;
  background-color: $bright-product-color;
  padding: 0 4px;
}

.unhighlighted {
  display: inline-block;
}

.map-container .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  background-color: $dark-color;
  input {
    color: $base-text-color;
  }
}

.MuiDialog-root {
  z-index: 3000 !important;
}

.MuiPaper-root {
  width: 472px !important;
  font-family: 'Proxima Nova', sans-serif !important;

  h2,
  button {
    font-family: inherit !important;
  }

  h2 {
    @include font(32px, 500, 32px, false);
    color: $base-color;
    text-align: center;
    margin-top: 12px;
  }

  .MuiDialogContentText-root {
    margin-top: 8px;
    @include font(18px, 500, 32px, false);
    color: $base-color;
  }

  button {
    height: 40px;
    width: 216px;
    background-color: $pale-grey-color;
    color: $dark-yellow-color;
    border-radius: 5px;
    @include font(16px, 600, 20px, false);

    &:hover {
      background-color: $pale-grey-color;
      opacity: 0.8;
    }

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    &:last-child {
      background-color: $yellow-color;

      &:hover {
        background-color: $yellow-color;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 14px 16px 40px;
  }
}

:root {
  --mui-palette-primary-main: $orange;
  /* ...other variables */
}

.colored-title {
  color: $title-color;
}

.astra-selector {
  width: 100%;
  @include font(14px, 400, 24px, 0em);
  color: $light-text-color;

  &__control {
    background-color: $light-color !important;
    border-style: none !important;
  }
}

.icon-container {
  svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
  }
}

.icon-container path {
  fill: $base-text-color;
}

.icon-container:hover path {
  fill: $dark-product-color;
}

.icon-container:disabled path {
  fill: $bright-color;
}

.icon-close {
  svg {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
  }
}

.icon-close path {
  fill: $base-text-color;
}

.icon-close:hover path {
  fill: $error-color;
}

.icon-close:disabled path {
  fill: $bright-color;
}

.progress-bar {
  height: 3px !important;
}
