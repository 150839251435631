.spinner {
  &__icon {
    svg {
      display: inline-block;
      animation: rotate 2s infinite;
      circle {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: spinner-dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
        fill: transparent;
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}
