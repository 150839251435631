.default-custom-cursor {
  cursor: url('../images/cursors/cursorDefault.svg'), default !important;
}

.crosshair-custom-cursor {
  cursor: url('../images/cursors/cursorCrosshair.svg') 12 12, crosshair !important;
}

.grab-custom-cursor {
  cursor: url('../images/cursors/cursorGrab.svg') 12 12, grab !important;
}

.grabbing-custom-cursor {
  cursor: url('../images/cursors/cursorGrabbing.svg'), grabbing !important;
}

.copy-custom-cursor {
  cursor: url('../images/cursors/cursorCopy.svg'), copy !important;
}

.move-custom-cursor {
  cursor: url('../images/cursors/cursorMove.svg'), move !important;
}

.pointer-custom-cursor {
  cursor: url('../images/cursors/cursorPointer.svg'), pointer !important;
}
