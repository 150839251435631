@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.geospoof-card {
  width: 100%;
  gap: 7px;
  @include flex(column, false, false);
  cursor: pointer;

  &__tooltip {
    border: none;
    background-color: $white-color;
    padding: 5px 10px;
  }

  &.skeleton {
    overflow: unset;
  }

  &:hover {
    background-color: $ultrablack-color;
  }

  &__wrapper {
    width: 100%;
    @include flex(row, space-between, stretch);
  }

  &__details {
    @include flex(row, false, false);
    gap: 8px;
    flex: 2;
  }

  &__info {
    @include flex(column, flex-start, flex-start);
    gap: 6px;

    &__name {
      width: 100%;
      color: $title-color;
    }

    &__search-name {
      color: $grey-03;
    }

    &__date {
      color: $grey-03;
    }

    &__username {
      width: 100%;
      color: $grey-03;

      &__link {
        margin-right: 8px;
      }
    }

    &__name,
    &__username,
    &__machine {
      max-width: 155px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__machine {
      color: $green-color;

      &--unsuccessful {
        color: $red-color-3;
      }
    }
  }

  &__position {
    @include flex(column, flex-end, flex-end);
    flex: 1;
    gap: 5px;

    &__distance {
      color: $light-grey;
    }

    &__copy-btn {
      padding: 0;
      text-align: center;
      border: none;
      background: none;
      cursor: pointer;
    }

    &__user-id {
      white-space: nowrap;
      color: $grey-03;
    }
  }

  &--active {
    background-color: $grey-03 !important;

    &:hover {
      background-color: $grey-03;
    }

    .geospoof-card {
      &__info {
        &__name {
          color: $white-color;
        }

        &__username {
          color: $pink-color;

          &__link {
            color: $turquoise;
          }
        }

        &__date {
          color: $pink-color;
        }
      }

      &__position {
        color: $pink-color;

        &__distance {
          color: $pale-grey-color;
        }

        &__user-id {
          color: $pink-color;
        }
      }
    }
  }
}

.geospoof-card-info {
  svg path {
    fill: $base-text-color;
  }

  &:hover {
    color: $title-color;

    svg path {
      fill: $title-color;
    }
  }
}
