@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'styles/typography.scss';

.entity-card-select {
  .select__body {
    height: unset;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .astra-selector {
    line-height: 20px;
  }

  .astra-selector__input-container {
    margin: 0;
    padding: 0;
  }

  .astra-selector__multi-value {
    background-color: $ultrablack-color;
    padding: 6px 12px;
    border-radius: 5px;
    margin: 0;

    &__label {
      @extend .tpg-c2;
      color: $base-text-color;
    }

    &__remove {
      svg {
        fill: $bright-product-color;
      }
      &:hover {
        background-color: transparent;
        path {
          fill: $title-color;
        }
      }
    }
  }

  .astra-selector__control {
    min-height: unset;
    background-color: transparent !important;
    padding: 0;
  }

  .astra-selector__value-container {
    padding: 0;
    gap: 4px;
  }

  .astra-selector__indicator-separator {
    background-color: transparent !important;
  }

  .astra-selector__dropdown-indicator {
    padding: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .astra-selector__placeholder {
    margin: 0;
    color: $title-color;
  }
}

.entity-card-datepicker {
  min-height: unset !important;
  input {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.entity-card-datepicker__untouched {
  input {
    color: $title-color !important;
  }
}

.entity-card-datepicker__touched {
  input {
    color: $base-text-color !important;
  }
}

.table-entity {
  position: relative;
  border: 1px solid $light-text-color;
  border-radius: 10px;
  height: 87vh;
  box-sizing: border-box;
  background-color: $ultrablack-color;

  &__header {
    background-color: $dark-color;
    border-bottom: 1px solid $light-text-color;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &__body {
    height: 100%;
    path {
      fill: $title-color;
    }
    &__right {
      border-left: 1px solid $light-text-color;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    align-items: center;
    background-color: $dark-color;
    border-top: 1px solid $light-text-color;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__params-table {
    border: 1px solid $main-product-color;
    border-radius: 10px;

    &__row {
      align-content: center;
      height: 45px;
      &__with-border {
        border-top: 1px solid $main-product-color;
      }
    }

    &__cell {
      border-right: 1px solid $main-product-color;
    }
  }
}
