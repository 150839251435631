@import 'styles/variables.scss';
@import 'styles/_typography.scss';

button {
  background-color: $dark-product-color;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border-color: transparent;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease-out;
  color: $title-color;

  &:hover {
    background-color: $main-product-color;
  }
}

.smart-button {
  &__disabled {
    background-color: $ultrablack-color;
    opacity: 0.2;

    &:hover {
      color: $title-color;
      background-color: $light-color;
    }
  }

  &__error {
    background-color: $red-color-2;

    &:hover {
      background-color: $error-color;
    }
  }

  &__default {
    background-color: $light-grey;

    &:hover {
      background-color: $grey-03;
    }
  }

  &__text {
    color: inherit;
  }
}
