@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.table-root {
  border: 1px solid $light-text-color;
  border-radius: 10px;
  background-color: $light-color;
  height: calc(100% - 120px);

  overflow: auto;

  &__header {
    height: 20px;
  }
}
