@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.not-found {
  height: 100%;
  @include flex(false, center, center);
  background-color: $dark-color;

  &__body {
    width: 100%;
    height: 250px;
    max-width: 500px;
    padding: 28px 16px 40px;
    border-radius: 5px;
    background: $ultrablack-color;
    @include flex(column, space-evenly, center);
  }
}
